import React from 'react';
import moment from 'moment';
import {Tooltip } from "reactstrap";




class ListCall extends React.Component {

    constructor(props) {
        super(props);
        this.state  = {
            tooltipCallback : {},
            isCompact: false, // 기본값은 table 형태
        }
    }

    handleResize = () => {
        this.setState({ isCompact: window.innerWidth < 240 }); // 240px 이하일 때 카드형
    };
    
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // 초기 로드 시에도 호출
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    onClickIdCall = (itemCall) => {
        this.props.onClickIdCall(itemCall);// props get id
    }

    tooltipCallbackToggle = call_id =>{
        this.setState({
            tooltipCallback : {
                ...this.state.tooltipCallback,
                ['tooltipCallback'+call_id] : !this.state.tooltipCallback['tooltipCallback'+call_id]
            }
        })
    }
    render() {
        const {
            callList,
            call,
            role,
            isDeleteList,
            isDelete,
            callTabReceived,
            callTabSendingCall,
            callTab,
            roleCheckDelete,
            search_key,
        } = this.props;
    
        const { tooltipCallback, isCompact } = this.state;
    
        // 카드형 레이아웃 렌더링
        if (isCompact) {
            return (
                <div className="card_layout">
                    {callList.length > 0 ? callList.map((item, index) => (
                        <div key={index} className={`card_item ${call.call_id === item.call_id ? 'active' : ''}`}
                             onClick={() => this.onClickIdCall(item)}
                        >
                            <div className="card_header">
                                <h4>{item.call_cust_name}</h4>
                                <span>{item.call_tel}</span>
                            </div>
                            <div className="card_body">
                                <p>{item.call_date_update ? '전일' : item.call_date}</p>
                                {(callTabReceived === 2 || callTabSendingCall === 2) && item.callback === 1 && (
                                    <div className="tooltip_wrapper">
                                        <a id={`Tooltip-${item.call_id}`} className="tooltip_trigger">
                                            <i className="icontooltip">
                                                <Tooltip
                                                    placement="bottom"
                                                    isOpen={tooltipCallback[`tooltipCallback${item.call_id}`]}
                                                    target={`Tooltip-${item.call_id}`}
                                                    toggle={() => this.tooltipCallbackToggle(item.call_id)}
                                                    popperClassName="font-size-16"
                                                >
                                                    {item.user ? item.user.usr_name : ""}
                                                </Tooltip>
                                            </i>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    )) : (
                        <div className="no_data">
                            {search_key ? '결과값 없음' : 'No Data'}
                        </div>
                    )}
                </div>
            );
        }
    
        // 기본 테이블 렌더링
        return (
            <div className="custom_table nover">
                {role && (
                    <div className="box_move_top">
                        <input
                            type="checkbox"
                            id="check_remove_call"
                            checked={isDelete}
                            onChange={isDeleteList}
                        />
                        <label className="check_remove_call" htmlFor="check_remove_call">삭제콜</label>
                    </div>
                )}
                <table className="table st_table_1">
                    <thead>
                        <tr>
                            <th width="25%">이름</th>
                            <th width="25%">전화번호</th>
                            <th width="50%">일자</th>
                        </tr>
                    </thead>
                    <tbody>
                        {callList.length > 0 ? callList.map((item, index) => (
                            <tr
                                key={index}
                                onClick={() => this.onClickIdCall(item)}
                                className={call.call_id === item.call_id ? 'active' : ''}
                            >
                                <td>{item.call_cust_name}</td>
                                <td>{item.call_tel}{item.count_call_tel ? `(${item.count_call_tel})` : ''}</td>
                                <td>
                                    <div className="check_center">
                                        <span className="left">{item.call_date_update ? '전일' : item.call_date}</span>
                                        {role && item.is_deleted === 1 && <i className="fas col_check fa-check-square" />}
                                        {(callTabReceived === 2 || callTabSendingCall === 2) && item.callback === 1 && (
                                            <React.Fragment>
                                                &ensp;
                                                <a id={`Tooltip-${item.call_id}`} className="box_tooltip">
                                                    <i className="icontooltip">
                                                        <Tooltip
                                                            placement="bottom"
                                                            isOpen={tooltipCallback[`tooltipCallback${item.call_id}`]}
                                                            target={`Tooltip-${item.call_id}`}
                                                            toggle={() => this.tooltipCallbackToggle(item.call_id)}
                                                            popperClassName="font-size-16"
                                                        >
                                                            {item.user ? item.user.usr_name : ""}
                                                        </Tooltip>
                                                    </i>
                                                </a>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )) : (
                            <tr className="text-center">
                                <td colSpan={3}>{search_key ? '결과값 없음' : 'No Data'}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ListCall;
