import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import SmsSetting from './../SmsSetting';
import { NavLink, Link } from "react-router-dom";
import { renderMessage } from '../../../utils/helpers';
import Pagination from "react-js-pagination";
import NotificationSystem from 'react-notification-system';
import TextareaAutosize from 'react-autosize-textarea';
import {
	fetchSmsBulkSendingAction, unmountSmsHistoryAction
} from "../../../store/actions/smsHistory";
import { createMessageHistoryAction, importExcelAction } from '../../../store/actions/textManager'
import HasRole from "../../../utils/entrust/HasRole";
import { hasRole } from "../../../utils/entrust/entrust";
import progressBar from '../../../utils/progressBar';
import * as webSocketApi from './../../../utils/websocketApi';

import * as apipostimageatalk from "./../../../utils/apipostimageatalk";
import _ from 'lodash';
import api from "./../../../utils/api";

import DatePicker from 'react-datepicker';
import moment from 'moment';

import { CHANGE_SCENE_COMPANY, CHANGE_SCENE_LOT } from "../../../store/type";

class SmsBulkSending extends Component {
	constructor(props) {

		console.log('props:', props);

		super(props);
		this.state = {
			id: this.props.user.csta_id,
			password: this.props.user.csta_pwd,
			deviceObject: this.props.user.usr_tel,
			lots_id: this.props.sceneLostId,
			company_id: this.props.sceneCompanyId,
			accessToken: '',
			sms_header: '',
			sms_footer: '',
			bulkSendingList: [],
			currentBulkSending: null,
			listPhoneNumber: [],
			tempPhoneNumber: [],
			failPhoneNumber: [],
			send_contents: [],
			sendSuccess: 0,
			downloadExample: '',
			maxLength: 0,
			reserved_date: moment().format('YYYY-MM-DD'),
			reserved_hour: moment().format('H'),
			reserved_minute: moment().format('m'),
			list_type: 1,
			list_date_start: null,
			list_date_end: null,
			opt_advertize_form: true,
			opt_duplicate_check: true,
			opt_reject_check: true,
			opt_reserved_check: false,
			opt_none_cellphone_check: false,
			opt_cellphone_check: false,
			opt_title_number_check: false,
			opt_filter_period: false,
			send_total: 0,
			send_fail: 0,
			file: '',
			fileInput: '',
			excelFile: {
				file: '',
				fileName: ''
			},
			notification: {
				allowHTML: false,
			},
			isFocused: false, // 선택 상태를 관리
		}
	}

	ApplyList = () => {

		progressBar.start();
		let { company_id, lots_id, list_type, opt_filter_period, list_date_start, list_date_end } = this.state

		console.log('this.state:', this.state);

		list_date_start = moment(list_date_start).format('YYYY-MM-DD');
		list_date_end = moment(list_date_end).format('YYYY-MM-DD');

		let params = {
			company_id, lots_id, list_type, opt_filter_period, list_date_start, list_date_end
		}

		console.log('params:', params);

		api.bulkSending.getSendList(params).then(res => {
			console.log('getSendList:', res?.data?.data?.list);
			progressBar.done();
			this.setState({
				tempPhoneNumber: [],
				listPhoneNumber: [],
				failPhoneNumber: [],
				sendSuccess: 0,
				send_total: 0,
				send_fail: 0,
				file: '',
				fileInput: '',
				excelFile: {
					file: '',
					fileName: ''
				},
			}, () => { // lots_id 가 변했다.
				let list = res?.data?.data?.list;
				let temp_list = [];
				console.log('list:', list);
				list.map((items, key) => {
					temp_list.push(items.call_tel);
				});
				console.log('temp_list:', temp_list);
				this.setState({
					tempPhoneNumber: temp_list,
				}, () => {

				})

			})
		})
			.catch((error) => {
				this.addNotification({
					title: <FormattedMessage
						id="dashboard.Error"
						defaultMessage="Error!" />,
					message: "서버로 부터 임시 발송 리스트를 받아오는데 실패했습니다.",
					level: 'error',
				})
				progressBar.done();
			});

	}

	set_lots_header = (lots_id) => {

		let params = {
			lots_id
		}

		api.bulkSending.getSMSHeader(params).then(res => {
			console.log('res:', res);
			let info = res?.data?.data;
			if (info) {
				this.setState({
					sms_header: info[0].sms_header,
					sms_footer: info[0].sms_footer
				}, () => { // lots_id 가 변했다.

				});
			}
		});

	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isChangeScene && nextProps.sceneLostId !== this.state.sceneLostId && nextProps.typeOfChange === CHANGE_SCENE_LOT) {
			this.setState({
				lots_id: nextProps.sceneLostId,
			}, () => { // lots_id 가 변했다.
				this.set_lots_header(nextProps.sceneLostId);
			})
		}
		if (nextProps.isChangeScene && nextProps.sceneCompanyId !== this.state.sceneCompanyId && nextProps.typeOfChange === CHANGE_SCENE_COMPANY) {
			this.setState({
				company_id: nextProps.sceneCompanyId,
			}, () => {

			})
		}
	}

	FilterList = () => {
		progressBar.start();
		let { opt_advertize_form, opt_duplicate_check, opt_reject_check, opt_reserved_check, opt_none_cellphone_check, opt_cellphone_check, opt_title_number_check, reserved_date, reserved_hour, reserved_minute, tempPhoneNumber, currentBulkSending, sms_header, sms_footer } = this.state

		reserved_date = moment(reserved_date).format('YYYY-MM-DD');
		let params = {
			company_id: this.state.company_id,
			lots_id: this.state.lots_id,
			opt_advertize_form, opt_duplicate_check, opt_reject_check, opt_reserved_check, opt_none_cellphone_check, opt_cellphone_check, opt_title_number_check, reserved_date, reserved_hour, reserved_minute, tempPhoneNumber, currentBulkSending, sms_header, sms_footer
		}

		api.bulkSending.getFilterList(params).then(res => {
			console.log('getFilterList:', res);

			progressBar.done();

			let { company_id, lots_id, list_type, opt_filter_period, list_date_start, list_date_end } = this.state

			console.log('this.state:', this.state);
			this.setState({
				listPhoneNumber: [],
				failPhoneNumber: [],
				send_contents: [],
				sendSuccess: 0,
				send_total: 0,
				send_fail: 0,
			}, () => { // lots_id 가 변했다.
				let list = res?.data?.data?.data;
				let temp_list = [];
				console.log('list:', list);
				if (list) {
					list.map((items, key) => {
						temp_list.push(items.phone);
					});
					console.log('temp_list:', temp_list);
				} else {
					this.addNotification({
						title: <FormattedMessage
							id="dashboard.Error"
							defaultMessage="Error!" />,
						message: "일반번호 , 전국대표 번호 , 핸드폰 번호 등 모든 번호가 제거 되어있습니다.",
						level: 'error',
					})
				}
				this.setState({
					listPhoneNumber: temp_list,
					failPhoneNumber: res?.data?.data?.filter,
					send_contents: list
				}, () => {
					console.log('send_contents:', list);
				})

			})

		})
			.catch((error) => {
				this.addNotification({
					title: <FormattedMessage
						id="dashboard.Error"
						defaultMessage="Error!" />,
					message: "일반번호 , 전국대표 번호 , 핸드폰 번호 등 모든 번호가 제거 되어있습니다.",
					level: 'error',
				})
				progressBar.done();
			});

	}

	onChangeOpt = (e) => {
		console.log('e:', e);
		const target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			...this.state,
			[name]: value,
		});
		if (name == 'opt_reserved_check' && target.checked) alert('예약전송시에는 MMS(사진전송)가 지원되지 않습니다');

	}

	onChangeListType = (e) => {
		this.setState({
			...this.state,
			list_type: e.target.value,
		});
	}

	onChangeReservedDate = (date) => {
		this.setState({
			...this.state,
			reserved_date: date,
		});
	}

	onChangeStartDate = (date) => {
		let formatDate = moment(date).format('YYYY-MM-DD');
		this.setState({
			...this.state,
			opt_filter_period: true,
			list_date_start: date,
		});
	}

	onChangeEndDate = (date) => {
		let formatDate = moment(date).format('YYYY-MM-DD');
		this.setState({
			...this.state,
			opt_filter_period: true,
			list_date_end: date,
		});
	}

	ClearDate = () => {
		console.log('this.state:', this.state);
		this.setState({
			...this.state,
			opt_filter_period: false,
			list_date_start: null,
			list_date_end: null,
		});
	}

	contentsChange = (e) => { // 변경시 직접입력으로 변경되고~~

		const { opt_advertize_form, sms_header, sms_footer } = this.state;
		//console.log("e:",e);
		//console.log("currentBulkSending:",this.state.currentBulkSending);
		var maxlength = 90;
		var _byte = 0;
		//var str = opt_advertize_form ? sms_header + e.target.value + sms_footer : e.target.value;
		var str = e.target.value;
		var charStr = '';
		var finalStr = '';

		if (opt_advertize_form) {
			for (var i = 0; i < sms_header.length; i++) {
				charStr = str.charAt(i);
				if (escape(charStr).length > 4) {
					_byte += 2;
				} else {
					_byte++;
				}
			}
			for (var i = 0; i < sms_footer.length; i++) {
				charStr = str.charAt(i);
				if (escape(charStr).length > 4) {
					_byte += 2;
				} else {
					_byte++;
				}
			}
		}

		for (var i = 0; i < str.length; i++) {
			charStr = str.charAt(i);
			if (escape(charStr).length > 4) {
				_byte += 2;
			} else {
				_byte++;
			}
			if (_byte > 1999) break;
			else finalStr += charStr;
		}
		let { currentBulkSending } = this.state;

		currentBulkSending.content = finalStr;
		currentBulkSending.type = 'direct';

		if (currentBulkSending?.image_messages) {
			currentBulkSending.send_type = 1;
		} else currentBulkSending.send_type = _byte < maxlength ? 0 : 2;

		this.setState({
			...this.state,
			maxLength: _byte,
			currentBulkSending: currentBulkSending
		});

	}

	notificationSystem = React.createRef();
	addNotification = (data) => {
		const notification = this.notificationSystem.current;
		notification.addNotification({
			message: data.message,
			title: data.title,
			level: data.level
		});
	};

	componentDidMount() {
		let params = {
			type: 'paginate'
		}
		this.getCallBulkSendingSMS(params);

		if (!this.state.lots_id || this.state.lots_id == '') {
			this.addNotification({
				title: <FormattedMessage
					id="dashboard.Error"
					defaultMessage="Error!" />,
				message: <FormattedMessage
					id="dashboard.smssetting.chooselots"
					defaultMessage="Please choose lots!" />,
				level: 'error',
			})
		} else this.set_lots_header(this.state.lots_id);

		webSocketApi.getAccessPermission(
			this.state.id,
			this.state.password,
			'wss://rtc.goodfone.co.kr:9807', // 2024-03-20 9807 -> 9850
			'https://rtc.goodfone.co.kr:9850', // 2024-03-20 9807 -> 9850
			this.csta_event_callback
		).then(res => {
			this.addNotification({
				title: <FormattedMessage
					id="dashboard.Succeed"
					defaultMessage="Succeed!" />,
				message: <FormattedMessage
					id="dashboard.TelephoneCounselling.ConnectToServerSucceed"
					defaultMessage="Connect to server succeed!" />,
				level: 'success',
			})
		})
		.catch(error => {
			this.addNotification({
				title: <FormattedMessage
					id="dashboard.Error"
					defaultMessage="Error!" />,
				message: <FormattedMessage
					id="dashboard.TelephoneCounselling.ConnectToServerFailed"
					defaultMessage="Connect to server failed!" />,
				level: 'error',
			})
			progressBar.done();
		});
		// 최초 실행시 기본은 "직접입력" ?
		const itemDefault = {
			type: "direct",
			content: "",
			send_type: 0,
			image_messages: [],
		};
	
		// 버튼이 처음 렌더링될 때 클릭 이벤트를 실행
		this.selectBulkSending(itemDefault);			
	}

	getCallBulkSendingSMS = (params) => {
		progressBar.start();
		this.props.onFetchBulkSending(params).then(response => {
			this.setState({
				...this.state,
				bulkSendingList: response.data,
			});
			progressBar.done();
		}).catch(error => {
			progressBar.done();
		});
	};

	changePage = (pageNumber) => {
		let params = {
			type: 'paginate',
			page: pageNumber,
		}
		this.getCallBulkSendingSMS(params);
	}

	// select item in list bulk sending
	selectBulkSending = (bulkSending) => {

		console.log("bulkSending:", bulkSending);

		const { opt_advertize_form, sms_header, sms_footer } = this.state;

		var maxlength = 90;
		var _byte = 0;
		var str = bulkSending?.content;
		var charStr = '';
		var finalStr = '';

		if (opt_advertize_form) {
			for (var i = 0; i < sms_header.length; i++) {
				charStr = str.charAt(i);
				if (escape(charStr).length > 4) {
					_byte += 2;
				} else {
					_byte++;
				}
			}
			for (var i = 0; i < sms_footer.length; i++) {
				charStr = str.charAt(i);
				if (escape(charStr).length > 4) {
					_byte += 2;
				} else {
					_byte++;
				}
			}
		}

		for (var i = 0; i < str.length; i++) {
			charStr = str.charAt(i);
			if (escape(charStr).length > 4) {
				_byte += 2;
			} else {
				_byte++;
			}
			if (_byte > 1999) break;
			else finalStr += charStr;
		}

		bulkSending.content = finalStr;
		if (bulkSending?.image_messages) {
			bulkSending.send_type = 1;
		} else bulkSending.send_type = _byte < maxlength ? 0 : 2;

		this.setState({
			...this.state,
			maxLength: _byte,
			currentBulkSending: bulkSending
		});

		//        this.setState({
		//            ...this.state,
		//            currentBulkSending: bulkSending,
		//        }, () => {
		//            //console.log(this.state, 'state_after');
		//        })
	}

	csta_event_callback = (event_type, dataObject) => {
		//this.trace_event_type(event_type);
		if (event_type == 'SIGN-IN') {
			console.log('event sign-in', dataObject);
			this.state.accessToken = dataObject.access_token;
			this.setState({
				...this.state,
				accessToken: dataObject.access_token
			})
			// Set Online when sign in
			webSocketApi.cstaSetDoNotDisturb(
				this.state.deviceObject,
				0,
				this.state.accessToken);
			webSocketApi.cstaMonitorStart(this.state.deviceObject, this.state.accessToken);
		} else if (event_type == 'SIGN_OFF') {
			console.log('event SIGN_OFF', dataObject);
			webSocketApi.cstaMonitorStop(this.state.deviceObject, this.state.accessToken);
		}
	};

	imageShow = data => {
		if (data && data.send_type == 1 && Array.isArray(data.image_messages)) {
			return data.image_messages.map((items, key) => {
				return (
					<li key={key} className="img_item"><img src={items.image_path} /></li>
				)
			})
		}
	};

	onChangeFile = (e) => {
		this.setState({
			...this.state,
			excelFile: {
				file: e.target.files[0],
				fileName: e.target.files[0].name
			}
		}, () => {
			// console.log(this.state.excelFile, 'excelFile');
		})
	}

	onClickAddFile = () => {
		let { excelFile } = this.state
		let formData = new FormData()
		formData.append('file', excelFile.file)
		this.props.importExcel(formData).then(res => {
			this.setState({
				...this.state,
				tempPhoneNumber: res
			})
		}).catch(error => {
			this.setState({
				...this.state,
				tempPhoneNumber: [],
				notification: {
					allowHTML: true,
				}
			}, () => {
				this.addNotification({
					title: <FormattedMessage
						id="dashboard.Error"
						defaultMessage="Error !" />,
					level: 'error',
					message: renderMessage(error.data.errors.message),
				})
				progressBar.done();
			})
		})
	}

	renderPhoneNumber = (data) => {
		if (data.length > 0) {
			let x = ''
			data.map((item) => {
				x += item + '\n'
			})
			return x
		} else
			return ''
	}

	deleteListPhoneNumber = () => {
		this.setState({
			...this.state,
			tempPhoneNumber: [],
			listPhoneNumber: [],
			failPhoneNumber: [],
			send_contents: [],
			sendSuccess: 0,
			send_total: 0,
			send_fail: 0,
		})
	}

	sendListPhoneNumber = (currentBulkSending, listPhoneNumber) => {
		// send_type: 0 => send SMS
		// send_type: 1 => send MMS

		if (listPhoneNumber.length > 0) {
			this.sendMMS(listPhoneNumber, currentBulkSending)
			// OLD CSTAT API
			//this.bulkRecursMMS(0, listPhoneNumber, currentBulkSending)
			//if (currentBulkSending.send_type == 0) {  
			//    this.bulkRecursSMS(0, listPhoneNumber, currentBulkSending)
			//} else {
			//    this.bulkRecursMMS(0, listPhoneNumber, currentBulkSending)
			//}
		} else {
			this.setState({
				...this.state,
				notification: {
					allowHTML: false,
				}
			}, () => {
				this.addNotification({
					title: <FormattedMessage
						id="dashboard.Succeed"
						defaultMessage="Succeed" />,
					message: 'Send message successfully',
					level: 'success',
				})
				progressBar.done();
			})
		}
	}

	sendMMS = (listPhone, currentBulkSending) => {

		progressBar.start();

		let { opt_reserved_check, reserved_date, reserved_hour, reserved_minute } = this.state

		reserved_date = moment(reserved_date).format('YYYY-MM-DD');
		let params = {
			company_id: this.state.company_id,
			lots_id: this.state.lots_id,
			opt_reserved_check, reserved_date, reserved_hour, reserved_minute, listPhone, currentBulkSending
		}

		api.bulkSending.SendMMSV2(params).then(res => {
			console.log('SendMMSV2:', res);
			progressBar.done();
			this.setState({
				tempPhoneNumber: [],
				listPhoneNumber: [],
				failPhoneNumber: [],
				sendSuccess: res.data.data.success,
				send_total: res.data.data.success + res.data.data.fail,
				send_fail: res.data.data.fail,
			}, () => { // lots_id 가 변했다.

				this.addNotification({
					title: <FormattedMessage
						id="dashboard.Succeed"
						defaultMessage="Succeed" />,
					message: 'Send message successfully',
					level: 'success',
				})

				//					let list = res?.data?.data?.list;
				//					let temp_list = [];
				//					console.log('list:',list);
				//					list.map((items, key) => {
				//						temp_list.push(items.call_tel);
				//					});
				//					console.log('temp_list:',temp_list);
				//					this.setState({
				//							tempPhoneNumber: temp_list,
				//					}, () => {
				//
				//					})

			})
		})
			.catch((error) => {
				console.log('error:', error);
				this.addNotification({
					title: <FormattedMessage
						id="dashboard.Error"
						defaultMessage="Error!" />,
					message: "대량발송중 일부 메시지 전송에 실패했습니다.",
					level: 'error',
				})
				progressBar.done();
			});



	}


	/**
	 * SMS function
	 */
	bulkRecursSMS = (i, listPhone, currentBulkSending) => {
		if (i < listPhone.length) {
			this.sendSMSBulk(i, listPhone, currentBulkSending).then(res => {
				// debugger
				this.sendSuccess(i)
				this.bulkRecursSMS(i + 1, listPhone, currentBulkSending)
			})
			if (i == listPhone.length - 1) {
				this.setState({
					...this.state,
					notification: {
						allowHTML: false,
					}
				}, () => {
					this.addNotification({
						title: <FormattedMessage
							id="dashboard.Succeed"
							defaultMessage="Succeed !" />,
						level: 'success',
						message: 'Send message successfully',
					})
					progressBar.done();
				})
			}
		}
	}

	sendSMSBulk = (i, listPhone, currentBulkSending) => {
		let user = this.props.user
		let data = {
			user_id: user.csta_id,
			deviceObject: user.usr_tel.replace(/-/g, ""),
			to_user: listPhone[i],
			oauth_token: this.state.accessToken,
			msg: currentBulkSending.content,
		}

		let dataMesshistory = {
			mess_id: currentBulkSending.mess_id,
			rec_usr_id: user.usr_id,
			phone_number: listPhone[i],
			send_type: 2
		}
		this.props.createMessageHistory(dataMesshistory)
		return webSocketApi.SendSMSBulk(data.user_id, data.deviceObject, data.to_user, data.msg, ""); // data.oauth_token) 일단 실패처리
	}

	sendSuccess = () => {
		let curSuccess = this.state.sendSuccess;
		this.setState({
			...this.state,
			sendSuccess: curSuccess + 1
		})
	}

	sendFail = (phone) => {
		console.log("phone:", phone);
		let { send_fail, failPhoneNumber } = this.state
		console.log("send_fail:", send_fail);
		console.log("failPhoneNumber:", failPhoneNumber);
		if (failPhoneNumber.length > 0) failPhoneNumber = failPhoneNumber.concat(phone);
		else failPhoneNumber.push(phone);
		this.setState({
			...this.state,
			send_fail: 1,
			failPhoneNumber: failPhoneNumber
		}, () => {
			console.log("send_fail:", this.state.send_fail);
			console.log("failPhoneNumber:", this.state.failPhoneNumber);
		})
	}

	sendTotal = () => {
		let curTotal = this.state.send_total;
		this.setState({
			...this.state,
			send_total: curTotal + 1
		})
	}

	handlePaste = (e) => { // paste
		console.log(e.clipboardData.getData('text'));
		let text = e.clipboardData.getData('text');
		if (text) {
			let prev_temp = this.state.tempPhoneNumber;
			let list = text.split(/\r?\n/);
			let temp_list = [];
			console.log('list:', list);
			list.map((items, key) => {
				if (items !== "") {
					var regexPattern = /[^0-9]/g;
					var items_alpha = items.replace(regexPattern, "");
					if (items_alpha !== "") {
						temp_list.push(items_alpha);
					}
				}
			});
			console.log('temp_list:', temp_list);
			this.setState({
				tempPhoneNumber: prev_temp.concat(temp_list),
			}, () => {
				console.log('new_array:', prev_temp.concat(temp_list));
			})
		}
	}

	/**
	 * MMS function
	 */
	sendMMSBulk = (i, listPhone, currentBulkSending) => {
		// 발송 실패 ?
		//if (!listPhone[i].available) return false
		let user = this.props.user
		//console.log('list:',listPhone[i]);
		if (currentBulkSending.image_messages.length > 0) {
			let data = {
				user_id: user.csta_id,
				deviceObject: user.usr_tel.replace(/-/g, ""),
				to_user: listPhone[i].phone,
				oauth_token: this.state.accessToken,
				msg: listPhone[i].contents
			}
			currentBulkSending.image_messages.map((image, index) => {
				data[`attach${index + 1}`] = {
					origin_name: image.image_name,
					upload_file: image.image_name,
					upload_path: image.image_path,
					type: "image"
				}
			})
			let dataMesshistory = {
				mess_id: currentBulkSending.mess_id,
				rec_usr_id: user.usr_id,
				phone_number: listPhone[i].phone,
				send_type: 2
			}
			this.props.createMessageHistory(dataMesshistory)
			return webSocketApi.SendMMSBulk(data)
		} else {
			let data = {
				user_id: user.csta_id,
				deviceObject: user.usr_tel.replace(/-/g, ""),
				to_user: listPhone[i].phone,
				oauth_token: this.state.accessToken,
				msg: listPhone[i].contents
			}
			let dataMesshistory = {
				mess_id: currentBulkSending.mess_id,
				rec_usr_id: user.usr_id,
				phone_number: listPhone[i].phone,
				send_type: 2
			}
			this.props.createMessageHistory(dataMesshistory)
			return webSocketApi.SendSMSBulk(data.user_id, data.deviceObject, data.to_user, data.msg, data.oauth_token)
		}
	}

	bulkRecursMMS = (i, listPhone, currentBulkSending) => {
		if (i < listPhone.length) {
			if (listPhone[i].available) {
				this.sendMMSBulk(i, listPhone, currentBulkSending).then(res => {
					console.log('res:', res)
					if (res) this.sendSuccess(i);
					else this.sendFail(listPhone[i].phone);
					this.bulkRecursMMS(i + 1, listPhone, currentBulkSending);
				})
			} else {
				this.sendFail(listPhone[i].phone);
				this.bulkRecursMMS(i + 1, listPhone, currentBulkSending);
			}
			if (i == listPhone.length - 1) {
				this.setState({
					...this.state,
					notification: {
						allowHTML: false,
					}
				}, () => {
					this.addNotification({
						title: <FormattedMessage
							id="dashboard.Succeed"
							defaultMessage="Succeed !" />,
						level: 'success',
						message: 'Send message successfully',
					})
					progressBar.done();
				})
			}
		}
	}

	downloadExample = () => {
		const URL = process.env.NODE_ENV === 'production'
			? `${process.env.REACT_APP_API_URL_PROD}/download/phonenumber.xls`
			: `${process.env.REACT_APP_API_URL_DEV}/download/phonenumber.xls`
		window.open(URL);
	}

	buildOptions(max, unit, def) {
		var arr = [];

		for (let i = 0; i <= max; i++) {
			if (i == def) arr.push(<option key={i} value={i} selected>{i}{unit}</option>)
			else arr.push(<option key={i} value={i}>{i}{unit}</option>)
		}

		return arr;
	}

	handleFocus = () => {
		this.setState({ isFocused: true }); // 선택되었을 때 상태 업데이트
	};

	handleBlur = () => {
		this.setState({ isFocused: false }); // 선택 해제되었을 때 상태 복구
	};
	
	render() {
		const {
			maxLength,
			file,
			sms_header,
			sms_footer,
			accessToken,
			reserved_date,
			reserved_hour,
			reserved_minute,
			list_type,
			list_date_start,
			list_date_end,
			bulkSendingList,
			currentBulkSending,
			listPhoneNumber,
			tempPhoneNumber,
			failPhoneNumber,
			sendSuccess,
			send_total,
			send_fail,
			excelFile,
			opt_advertize_form,
			opt_duplicate_check,
			opt_reject_check,
			opt_reserved_check,
			opt_none_cellphone_check,
			opt_title_number_check,
			opt_filter_period,
			opt_cellphone_check,
			send_contents,
			isFocused,
		} = this.state;

		return (
			<Fragment>
				<NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML} />

				<FormattedMessage id="dashboard.SmsSetting.BulkSending"
					defaultMessage="BulkSending">
					{message => <Helmet>
						<title>{message}</title>
					</Helmet>}
				</FormattedMessage>

				<div className="main_top_bar static_bar">
					<div className="box_sum_col_top">
						<div className="box_left">
							<ul className="nav nav_tabs">
								<li className="nav_item">
									<span className="nav_link">
										<FormattedMessage
											id="dashboard.SmsSetting.BulkSendingTitle"
											defaultMessage="BulkSending"
										/>
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="main_content">
					<div className="content_tabs_main content_static_tabs tab-content">
						<div className="tabs_static_item tabs_item1">
							<div className="box_content_static">

								<div className="left_col left_col_customer_care">
									<SmsSetting
										location={this.props.location}
									/>
								</div>

								<div class="content_col_nopading">
									<div class="list_control_box_preferences">
										<div class="content_left">
											<div class="content_column" style={{}}>
												<div class="column_box" style={{ paddingLeft: "15px", width: "35%" }}>
													<div class="heading_box"><h5 class="title_preferences">문자 항목</h5></div>
													<div class="control_box_item" style={{ border: "1px solid #343a40", minHeight: "250px", backgroundColor: "#f7f7f7" }}>
														<div class="card_body">
															<div class="custom_table">

																<button
																	type="button"
																	style={{ width: "100%", backgroundColor: "#fff", border: "1px solid #ccc", height: "32px" }}
																	className="input"
																	onClick={() => {

																		let itemDefault = {};

																		itemDefault = {
																			type: 'direct',
																			content: '',
																			send_type: 0,
																			image_messages: []
																		};

																		//																																			if(currentBulkSending && currentBulkSending.content){
																		//																																					itemDefault = {...currentBulkSending, image_messages: [], type: 'direct'};
																		//																																			}else{
																		//																																					itemDefault = { // set bien mac dinh để đúng định dạng item api fetch về
																		//																																							type: 'direct',
																		//																																							content : '',
																		//																																							send_type : 0, // mac dinh sms
																		//																																							image_messages : []
																		//																																					};
																		//																																			}

																		this.selectBulkSending(itemDefault)

																	}}
																>
																	직접입력
																</button>

																<table className="table st_table_5">
																	<tbody>
																		{/* render bulkSending list */}
																		{
																			bulkSendingList?.data?.length > 0 ?
																				bulkSendingList.data.map((item, index) => {
																					let active = currentBulkSending?.mess_id == item.mess_id ? 'active' : '';
																					item.type = 'template';
																					return (
																						<tr key={index} className={active}>
																							<td onClick={() => this.selectBulkSending(item)}>{item.title}</td>
																						</tr>
																					)
																				}) :
																				(
																					<tr>
																						<td colSpan={1}>
																							<div className="nodata">No Data</div>
																						</td>
																					</tr>
																				)
																		}
																	</tbody>
																</table>
															</div>
														</div>

														{
															bulkSendingList?.data?.length > 0 && (
																<div className="car_footer">
																	<nav aria-label="...">
																		<ul className="custom_pagination pagination">
																			<Pagination
																				itemClass="page-item"
																				linkClass="page-link"
																				activePage={bulkSendingList?.current_page}
																				itemsCountPerPage={bulkSendingList?.per_page}
																				totalItemsCount={bulkSendingList?.total || 0}
																				pageRangeDisplayed={10}
																				onChange={this.changePage}
																			/>
																		</ul>
																	</nav>
																</div>
															)
														}

													</div>
													<div className="heading_box" style={{ marginTop: "10px" }}>
														<h5 className="title_preferences">
															수신자 설정
														</h5>
													</div>
													<div className="control_box_item" style={{ border: "1px solid #343a40" }}>
														<div className="card_body">
															<div className="custom_table" style={{ backgroundColor: "white", position: "unset" }}>
																<table className="table st_table_5">
																	<tbody>
																		<tr>
																			<td colSpan="3">
																				<input type="radio" id="list_001" name="list_type" value="1" checked={list_type == 1 ? true : false} onChange={(e) => this.onChangeListType(e)} /> <label className="action_check" for="list_001">수신동의 고객 (수/발신)</label>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan="3">
																				<input type="radio" id="list_002" name="list_type" value="2" checked={list_type == 2 ? true : false} onChange={(e) => this.onChangeListType(e)} /> <label className="action_check" for="list_002">모든 고객 (수/발신)</label>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan="3">
																				<input type="radio" id="list_003" name="list_type" value="3" checked={list_type == 3 ? true : false} onChange={(e) => this.onChangeListType(e)} /> <label className="action_check" for="list_003">모든 고객 (수/발신/부재)</label>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan="3">
																				<input type="radio" id="list_004" name="list_type" value="4" checked={list_type == 4 ? true : false} onChange={(e) => this.onChangeListType(e)} /> <label className="action_check" for="list_004">수신거부 고객</label>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan="3">
																				<input type="checkbox" id="opt_100" name="opt_filter_period" value="1" checked={opt_filter_period ? true : false} onChange={this.onChangeOpt} /> <label className="action_check" for="opt_100" style={{ color: "#ff0f47" }}> 기간 설정 (해제시 전체 기간)</label>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<DatePicker
																					className={'form-control'}
																					selected={list_date_start ? moment(list_date_start) : ""}
																					onChange={this.onChangeStartDate}
																				/>
																				<span className="icon_date" />
																			</td>
																			<td>
																				~
																			</td>
																			<td>
																				<DatePicker
																					className={'form-control'}
																					selected={list_date_end ? moment(list_date_end) : ""}
																					onChange={this.onChangeEndDate}
																				/>
																				<span className="icon_date" />
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
													<div style={{ marginTop: "10px" }}>
														<button className="btn btn_001 m-1" style={{ width: "48%", backgroundColor: "#cbcccf", border: "1px solid #343a40" }} type="button" onClick={this.ClearDate}>초기화</button>
														<button className="btn btn_002 m-1" style={{ width: "48%", backgroundColor: "rgb(23,162,184)", color: "#fff" }} type="button" onClick={this.ApplyList}>적용</button>
													</div>
												</div>
												<div className="column_box" style={{ paddingLeft: "15px", width: "35%" }}>
													<div className="control_box_item">
														<div className="heading_box">
															<h5 className="title_preferences">
																문자 메세지 <span style={{ fontSize: "12px" }}>(내용 수정시 "문자 수신 리스트로 전송" 재전송 필요)</span>
															</h5>
														</div>
														<div className="card_body">
															<div className="control_textarea">
																<div class="box-border" style={{ border: "1px solid #343a40", backgroundColor: "#fff" }}>
																	{
																		opt_advertize_form && (<input type="text" className="form-control" value={sms_header} style={{ border: "0px" }} readonly />)
																	}
																	<textarea
																		className="text_area_in"
																		style={{ border: "0px" }}
																		name="sms_contents"
																		rows={10}
																		value={currentBulkSending?.content}
																		onChange={this.contentsChange}>
																	</textarea>
																	{
																		opt_advertize_form && (<input type="text" className="form-control" value={sms_footer} style={{ border: "0px" }} readonly />)
																	}

																	<div style={{ width: "100%", textAlign: "right", padding: "2px 7px", paddingBottom: "6px" }}>
																		<span className="byte" style={{ fontSize: "10px", color: "#666" }}>
																			{currentBulkSending ? (currentBulkSending?.send_type == 0 ? 'SMS (' + maxLength + ' Bytes)' : (currentBulkSending?.send_type == 1 ? 'MMS (' + maxLength + ' Bytes)' : 'LMS (' + maxLength + ' Bytes)')) : ""} </span>
																	</div>
																</div>
																<div className="box_upload_file" style={{ marginTop: "10px" }}>
																	<div className="box_input">

																		<div className="add-but">
																			<span><input
																				type="file"
																				className="add_file"
																				id="add_file"
																				multiple={false}
																				disabled={currentBulkSending?.image_messages?.length === 3 ? true : false}
																				onChange={(event) => {
																					let f = event.target.files;
																					if (f && f.length > 0) {
																						this.setState({
																							...this.state,
																							file: f[0],
																						});
																					}
																				}}
																			/>
																			<label htmlFor="add_file" style={{ width: "100%" }}><div class="btn-upload">{file ? file.name : '이미지 업로드(jpg,png,jpeg)'}</div></label></span>
																			<button
																				type="button"
																				className="btn btn_001"
																				style={{ marginLeft: "4px", width: "120px", backgroundColor: "rgb(23, 162, 184)", border: "1px solid #343a40", color: "#fff" }}
																				onClick={() => {
																					if (file) {
																						apipostimageatalk.sendCrmSmsImage(file, accessToken).then(res => {
																							let imagePush = currentBulkSending?.image_messages;
																							imagePush.push({
																								image_name: res.file_name,
																								image_path: `https://rtc.goodfone.co.kr/mms/${res.file_name}`
																							});
																							let updateBulkSending = currentBulkSending;
																							updateBulkSending.image_messages = imagePush;
																							updateBulkSending.send_type = 1;
																							this.setState({
																								...this.state,
																								file: null,
																								currentBulkSending: updateBulkSending
																							});
																						});
																					}
																				}}
																				disabled={currentBulkSending?.image_messages?.length === 3 ? true : false}
																			>추가</button>
																		</div>

																	</div>
																</div>


																<ul className="list_images">

																	{currentBulkSending?.image_messages?.length > 0 && currentBulkSending?.image_messages?.map((image, index) => {
																		return (
																			<div className="item_file" key={index}>
																				<span
																					className="close"
																					onClick={(e) => {
																						var itemSetImage = currentBulkSending?.image_messages;
																						_.remove(itemSetImage, function (n) {
																							return n.image_name == image.image_name;
																						})
																						let updateBulkSending = currentBulkSending;
																						updateBulkSending.image_messages = itemSetImage;
																						this.setState({
																							...this.state,
																							file: null,
																							currentBulkSending: updateBulkSending
																						});
																					}}
																				>
																					<i className="fas fa-times" />
																				</span>
																				<div className="img">
																					<img src={image.image_path} style={{ maxWidth: "120px", height: "auto" }} />
																				</div>
																			</div>
																		)
																	})}

																</ul>

																<div className="custom_table" style={{ backgroundColor: "white", position: "unset" }}>
																	<table className="table st_table_5">
																		<tbody style={{ border: "1px solid #343a40" }}>
																			<tr>
																				<td style={{ border: "none" }}>
																					<input type="checkbox" id="opt_001" name="opt_advertize_form" value="1" checked={opt_advertize_form ? true : false} onChange={this.onChangeOpt} /> <label className="action_check" for="opt_001">광고 양식</label>
																				</td>
																				<td style={{ border: "none" }}>
																					<input type="checkbox" id="opt_004" name="opt_none_cellphone_check" value="1" checked={opt_none_cellphone_check ? true : false} onChange={this.onChangeOpt} /> <label className="action_check" for="opt_004">일반 번호 제거</label>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ border: "none" }}>
																					<input type="checkbox" id="opt_003" name="opt_duplicate_check" value="1" checked={opt_duplicate_check ? true : false} onChange={this.onChangeOpt} /> <label className="action_check" for="opt_003">중복 번호 제거</label>
																				</td>
																				<td style={{ border: "none" }}>
																					<input type="checkbox" id="opt_006" name="opt_title_number_check" value="1" checked={opt_title_number_check ? true : false} onChange={this.onChangeOpt} /> <label className="action_check" for="opt_006">전국 대표번호 제거</label>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ border: "none" }}>
																					<input type="checkbox" id="opt_005" name="opt_reject_check" value="1" checked={opt_reject_check && list_type != 4 ? true : false} onChange={this.onChangeOpt} /> <label className="action_check" for="opt_005">수신거부 번호 제거</label>
																				</td>
																				<td style={{ border: "none" }}>
																					<input type="checkbox" id="opt_007" name="opt_cellphone_check" value="1" checked={opt_cellphone_check ? true : false} onChange={this.onChangeOpt} /> <label className="action_check" for="opt_007">핸드폰번호 제거</label>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ border: "none" }}>
																					<input type="checkbox" id="opt_002" name="opt_reserved_check" value="1" checked={opt_reserved_check ? true : false} onChange={this.onChangeOpt} /> <label className="action_check" for="opt_002">예약전송</label>
																				</td>
																			</tr>
																			{
																				opt_reserved_check && (
																					<tr>
																						<td colSpan="2" style={{ border: "none" }}>
																							<div className="list_btn">
																								<DatePicker
																									className="form-control"
																									selected={reserved_date ? moment(reserved_date) : moment()}
																									onChange={this.onChangeReservedDate}
																								/>
																								<select className="form-control col-3" name="reserved_hour" onChange={this.onChangeOpt}>
																									{this.buildOptions(23, '시', reserved_hour)}
																								</select>
																								<select className="form-control col-3" name="reserved_minute" onChange={this.onChangeOpt}>
																									{this.buildOptions(59, '분', reserved_minute)}
																								</select>
																							</div>
																						</td>
																					</tr>
																				)
																			}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="column_box" style={{ maxWidth: "24%", padding: "0px 15px" }}>
													<div className="control_box_item" style={{ margin: "0 auto" }}>
														<div className="heading_box">
															<h5 className="title_preferences" style={{ letterSpacing: "-1px" }}>
																임시 문자 발송 리스트 <span style={{ fontSize: "12px" }}>( ctrl + v : 붙여넣기 )</span>
															</h5>
														</div>
														<div className="card_body">
															<div className="control_textarea">

																<textarea
																	className="text_area_in"
																	rows={10}
																	//style={{ backgroundColor: "#fff", border: "1px solid #343a40", minHeight: "348px" }}
																	style={{
																		backgroundColor: isFocused ? "#e3f2fd" : "#fff", // 선택 상태를 강조
																		border: isFocused ? "2px solid #1e88e5" : "1px solid #343a40", // 선택 시 테두리 강조
																		boxShadow: isFocused ? "0 0 10px rgba(30, 136, 229, 0.5)" : "none", // 선택 시 그림자 효과
																		minHeight: "348px",
																	}}
																	//disabled={false} // 붙여넣기 가능하도록 disabled 해제
																	onFocus={this.handleFocus} // 선택되었을 때
																	onBlur={this.handleBlur} // 선택이 해제되었을 때																	
																	disabled={false}
																	readonly={true}
																	onPaste={this.handlePaste}
																	value={this.renderPhoneNumber(tempPhoneNumber)}
																/>

																<div className="box_input" style={{ marginTop: "10px", marginBottom: "2px" }}>

																	<label htmlFor="input_file_img" style={{ width: "100%" }}>
																		<div className="btn-upload" style={{ fontWeight: "500", fontSize: "15px" }}>{excelFile.fileName ? excelFile.fileName : '엑셀 업로드(xlsx)'}</div>
																	</label>

																	<input
																		type="file"
																		className="custom-file-input m-1"
																		id="input_file_img"
																		style={{ display: "none", width: "100%", backgroundColor: "#17a2b8" }}
																		onChange={(e) => this.onChangeFile(e)}
																		required
																		disabled={currentBulkSending ? false : true}
																	/>

																</div>

																<div className="list_btn" style={{ margin: "4px 0" }}>

																	<button
																		className="btn-upload"
																		style={{ fontWeight: "500", fontSize: "15px", backgroundColor: "rgb(23, 162, 184)", border: "1px solid #343a40", color: "#fff" }}
																		type="button"
																		onClick={() => this.onClickAddFile()}
																		disabled={currentBulkSending ? false : true}
																	>
																		추가
																	</button>

																</div>

																<div className="list_btn" style={{ margin: "4px 0" }}>

																	<button
																		className="btn btn_su btn_su3"
																		style={{ width: "100%", backgroundColor: "#cbcccf", border: "1px solid #343a40", marginRight: "2px" }}
																		disabled={currentBulkSending ? false : true}
																		onClick={() => this.downloadExample()}
																	>
																		샘플다운
																	</button>
																	<button
																		className="btn btn_su btn_su2"
																		style={{ width: "100%", backgroundColor: "#f85c5c", color: "#fff", border: "1px solid #343a40", marginLeft: "2px" }}
																		disabled={currentBulkSending ? false : true}
																		onClick={() => this.deleteListPhoneNumber()}
																	>
																		<FormattedMessage
																			id="SMS-SMS.DellAll"
																			defaultMessage="Delete all"
																		/>
																	</button>


																</div>

																<div className="list_btn">

																	<button
																		className="btn btn_su btn_su1"
																		style={{ width: "100%", backgroundColor: "rgb(23, 162, 184)", color: "#fff", border: "1px solid #343a40", marginTop: "4px" }}
																		type="button"
																		onClick={() => this.FilterList()}
																		disabled={currentBulkSending ? false : true}
																	>
																		문자 수신 리스트로 전송
																	</button>

																</div>

															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="content_right custom_color">

											<div className="list-flex">

												<div className="" style={{ width: "100%" }}>
													<div className="heading_box">
														<h5 className="title_preferences" style={{ padding: "10px 0px 0px 0px" }}>
															문자 수신 번호 리스트
														</h5>
													</div>
													<div className="control_in_ecxel">
														<div className="text_content">
															<textarea
																rows={10}
																style={{ border: "1px solid #343a40", minHeight: "348px" }}
																disabled
																value={this.renderPhoneNumber(listPhoneNumber)}
															/>
														</div>
														<div className="content_total">
															<div className="line" style={{ marginTop: "8px" }}><strong>총 갯수:</strong><span> {listPhoneNumber.length} </span></div>
															<div className="line" style={{ margin: "8px 0 0px" }}><strong>전송 수량:</strong><span> {send_total}  </span></div>
															<div className="line" style={{ margin: "8px 0 0px" }}><strong>전송 성공:</strong><span> {sendSuccess}  </span></div>
															<div className="line" style={{ margin: "8px 0 0px" }}><strong>전송 실패:</strong><span> {send_fail}  </span></div>
														</div>
													</div>
												</div>

												<div className="" style={{ width: "100%" }}>
													<div className="heading_box">
														<h5 className="title_preferences" style={{ padding: "10px 0px 0px 0px" }}>
															문자 전송 제외 리스트
														</h5>
													</div>
													<div className="control_in_ecxel">
														<div className="text_content">
															<textarea
																rows={10}
																style={{ border: "1px solid #343a40", minHeight: "348px" }}
																disabled
																value={this.renderPhoneNumber(failPhoneNumber)}
															/>
														</div>
														<div className="content_total">
															<div className="line" style={{ marginTop: "8px" }}><strong>총 갯수:</strong><span> {failPhoneNumber.length} </span></div>
														</div>
													</div>
												</div>

											</div>

											<div className="row">

												<div className="col-12">

													<div className="control_submit" style={{ marginTop: "17px" }}>
														<button
															className="btn btn-info btn-block ml-auto mr-auto mt-3"
															style={{ backgroundColor: "#00b494", border: "1px solid #343a40" }}
															disabled={currentBulkSending && listPhoneNumber.length > 0 ? false : true}
															onClick={() => this.sendListPhoneNumber(currentBulkSending, send_contents)}
														>
															<FormattedMessage
																id="SMS-SMS.SendSMS"
																defaultMessage="Send"
															/>
														</button>
													</div>

												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		// smsBulkSending: state.smsHistory.data,
		user: state.auth.user,
		sceneCompanyId: state.sceneSelect.company_id,
		sceneLostId: state.sceneSelect.lots_id,
		typeOfChange: state.sceneSelect.typeOfChange,
		isChangeScene: state.sceneSelect.isChangeScene,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		onFetchBulkSending: (params) => dispatch(fetchSmsBulkSendingAction(params)),
		onUnmountBulkSending: () => dispatch(unmountSmsHistoryAction()),
		importExcel: (data) => dispatch(importExcelAction(data)),
		createMessageHistory: (data) => dispatch(createMessageHistoryAction(data)),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(SmsBulkSending)
